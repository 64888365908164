import Utm from "../components/_global/utm";
// images
import TopBackground from "../images/turkey-header-img.jpg";
import BannerTurkeyPrices from "../images/banner-turkey.svg";
import TurkeyLogo from "../images/turkeyLogo.jpeg";

// icons
import CheckIcon from "../images/icon/check.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("turkish-government-support.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      notFull70: true,
      image: {
        src: TopBackground,
        alt: t("turkish-government-support.imgAlt"),
        smallHeight: true,
      },
    },
    priceTitle: {
      title: {
        text: t("turkish-government-support.priceMembers"),
        colorClass: "colorDark",
        extraClass: "text-center font40 lineHeight1",
      },
      separator: false,
    },
    bannerPricesImage: {
      src: BannerTurkeyPrices,
      alt: t("turkish-government-support.bannerPricesAlt"),
    },
    turkeyLogo: {
      src: TurkeyLogo,
      alt: t("turkish-government-support.logoAlt"),
    },
    plansTitle: {
      title: {
        text: t("turkish-government-support.choosePlan"),
        colorClass: "colorDark",
        extraClass: "text-center font40",
      },
      separator: false,
    },
    governmentText: [
      {
        text: t("turkish-government-support.governmentSupport"),
        colorClass: "colorDark",
        extraClass: "font20",
        textType: "paragraph",
      },
    ],
    detailsText: [
      {
        text: t("turkish-government-support.fullDetails"),
        colorClass: "colorDark",
        extraClass: "text-center font20",
        textType: "paragraph",
      },
      {
        text: t("turkish-government-support.contactUs"),
        url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        textType: "button",
        extraClass: "bigWidth",
      },
    ],
    plans: {
      tabList: [t("turkish-government-support.fabricSuppliers"), t("turkish-government-support.apparelMf")],
      tabContent: [
        {
          professional: {
            topPlan: {
              title: {
                text: t("turkish-government-support.professionalMembership"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              pretext: {
                text: t("turkish-government-support.allBasicPlus"),
                colorClass: "colorDarkLight font16",
              },
              icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
              separator: true,
              classSeparator: "greenStyle mt-0",
              itemsClass: "text-left pr-20 pl-20",
              customPBText: true,
              item: {
                text1: [
                  t("turkish-government-support.growNetwork"),
                  t("turkish-government-support.gainMarket"),
                  t("turkish-government-support.getMore"),
                  t("turkish-government-support.replyRequest"),
                ],
                text2: [
                  t("turkish-government-support.saveSampling"),
                  t("turkish-government-support.increaseVisibility"),
                  t("turkish-government-support.receiveGuidance"),
                ],
                colorClass: "colorDarkLight font18",
              },
            },
            sevenDayMark: {
              sevenDay: t("turkish-government-support.7days"),
              moneyBack: t("turkish-government-support.moneyBack"),
            },
            bottomPlan: {
              title: {
                text: t("turkish-government-support.free"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              text: {
                text: t("turkish-government-support.noCard"),
                colorClass: "colorDark",
                extraClass: "fontWeightBold text-center pb-30",
              },
              separator: false,
            },
            cta: {
              text: t("menu.joinNow"),
              url: (process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)) + "buyer",
              type: "mainColor",
              extraClass: "bigWidth",
            },
            createFree: t("turkish-government-support.createProfile"),
            sevenDayNote: t("turkish-government-support.7dayNote"),
            sixMonths: {
              title: {
                text: t("turkish-government-support.12months"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              separator: true,
              classSeparator: "greyStyle mt-0",
              createdGlobal: t("turkish-government-support.createdGlobal"),
              billedOnce: t("turkish-government-support.billedOnce"),
              price: t("turkish-government-support.1500euros"),
              cta: {
                text: t("menu.joinNow"),
                url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
                type: "mainColor",
                extraClass: "bigWidth",
              },
            },
            oneYear: {
              title: {
                text: t("turkish-government-support.24months"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              separator: true,
              classSeparator: "greyStyle mt-0",
              save500: t("turkish-government-support.save500"),
              billedOnce: t("turkish-government-support.billedOnce"),
              price: t("turkish-government-support.2500euros"),
              cta: {
                text: t("menu.joinNow"),
                url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
                type: "mainColor",
                extraClass: "bigWidth",
              },
            },
          },
        },
        {
          professional: {
            topPlan: {
              title: {
                text: t("turkish-government-support.professionalMembership"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              pretext: {
                text: t("turkish-government-support.allBasicPlus"),
                colorClass: "colorDarkLight font16",
              },
              icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
              separator: true,
              classSeparator: "greenStyle mt-0",
              itemsClass: "text-left pr-20 pl-20",
              customPBText: true,
              item: {
                text1: [
                  t("turkish-government-support.growNetwork"),
                  t("turkish-government-support.gainMarket"),
                  t("turkish-government-support.getMore"),
                ],
                text2: [
                  t("turkish-government-support.replyQuotations"),
                  t("turkish-government-support.googleOptimization"),
                  t("turkish-government-support.receiveGuidance"),
                ],
                colorClass: "colorDarkLight font18",
              },
            },
            sevenDayMark: {
              sevenDay: t("turkish-government-support.7days"),
              moneyBack: t("turkish-government-support.moneyBack"),
            },
            bottomPlan: {
              title: {
                text: t("turkish-government-support.free"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              text: {
                text: t("turkish-government-support.noCard"),
                colorClass: "colorDark",
                extraClass: "fontWeightBold text-center pb-30",
              },
              separator: false,
            },
            cta: {
              text: t("menu.joinNow"),
              url: (process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)) + "buyer",
              type: "mainColor",
              extraClass: "bigWidth",
            },
            createFree: t("turkish-government-support.createProfile"),
            sevenDayNote: t("turkish-government-support.7dayNote"),
            sixMonths: {
              title: {
                text: t("turkish-government-support.12months"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              separator: true,
              classSeparator: "greyStyle mt-0",
              createdGlobal: t("turkish-government-support.accessNetwork"),
              billedOnce: t("turkish-government-support.billedOnce"),
              price: t("turkish-government-support.1500euros"),
              cta: {
                text: t("menu.joinNow"),
                url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
                type: "mainColor",
                extraClass: "bigWidth",
              },
            },
            oneYear: {
              title: {
                text: t("turkish-government-support.24months"),
                colorClass: "colorDark",
                extraClass: "font40 text-center",
              },
              separator: true,
              classSeparator: "greyStyle mt-0",
              save500: t("turkish-government-support.save500"),
              billedOnce: t("turkish-government-support.billedOnce"),
              price: t("turkish-government-support.2500euros"),
              cta: {
                text: t("menu.joinNow"),
                url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
                type: "mainColor",
                extraClass: "bigWidth",
              },
            },
          },
        },
      ],
    },
  };
};
