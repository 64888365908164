import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/turkish-government-support";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import PlansTabs from "../components/_page/_plansTabs";
import TitleSeparator from "../components/titleSeparator";
import Text from "../components/text";

class TurkishSupport extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="turkish-government-support" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-0">
            <TitleSeparator info={this.text.priceTitle}></TitleSeparator>
            <img className="text-center pt-40 pb-50" src={this.text.bannerPricesImage.src} alt={this.text.bannerPricesImage.alt}></img>
          </div>
          <div className="paddingSidesBigger pt-30 pb-50">
            <div className="row">
              <div className="col-3">
                <img className="text-center w-100" src={this.text.turkeyLogo.src} alt={this.text.turkeyLogo.alt}></img>
              </div>
              <div className="col-9 d-table-cell m-auto">
                <Text text={this.text.governmentText}></Text>
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pb-50">
            <Text text={this.text.detailsText}></Text>
          </div>
          <div className="topDiagonal backgroundGrey paddingSidesTurkish text-center pt-100 pb-100">
            <PlansTabs
              hideFirst={true}
              tabList={this.text.plans.tabList}
              tabsPanel={this.text.plans.tabContent}
              hideBasic={true}
              hidePrice={true}
            ></PlansTabs>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(TurkishSupport);
